<template>
  <div>
    <el-row
      type="flex"
      :gutter="24"
      style="margin-bottom:10px"
    >
      <el-col :span="8">
        <div class="box">
          全区企业数量
          <div class="cavasbox">
            {{companyNumberObj.number}}
          </div>
          <!-- <div
            class="cavasbox"
            ref="companyNumber"
          ></div> -->
        </div>
      </el-col>
      <el-col :span="8">
        <div class="box">
          全区填报企业数量
          <div class="cavasbox">
            {{reportCompanyNumberObj.number}}
          </div>
          <!-- <div class="cavasbox" ref="reportCompanyNumber"></div> -->
        </div>
      </el-col>
   
      <el-col :span="8">
        <div class="box">
          全区样本企业填报率
          <div class="cavasbox">
            {{reportSampleCompanyNumberObj.number}}%
          </div>
          <!-- <div class="cavasbox" ref="reportSampleCompanyNumber"></div> -->
        </div>
      </el-col>
      <!-- <el-col :span="6">
        <div class="box">
          全区样本企业填报准确率
          <div class="cavasbox">
            {{reportSampleCompanyAccuracyObj.number}}%
          </div>
        </div>
      </el-col> -->
    </el-row>

  </div>
</template>

<script>
// import * as Chart from 'echarts'
export default {
  data(){
    return{
      companyNumberObj:{},
      reportCompanyNumberObj:{},
      reportSampleCompanyNumberObj:{},
      reportSampleCompanyAccuracyObj:{}
    }
  },
  mounted(){
    
  },
  methods:{
    onLoad(value){
      // this.companyNumberObj = value.k1
      // this.reportCompanyNumberObj = value.k2
      this.reportSampleCompanyNumberObj = value.k3
      this.reportSampleCompanyAccuracyObj = value.k4
    },
    onLoad2(value){
      this.companyNumberObj = value.k1
      this.reportCompanyNumberObj = value.k2
      this.reportSampleCompanyNumberObj.number = ((value.k2.number/value.k1.number)*100).toFixed(2)
      // this.companyNumberFun()
      // this.reportCompanyNumberFun()
    },
  }
}
</script>

<style scoped>
.box {
  background-color: #fff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: #999999;
  height: 100px;
}
.cavasbox {
  font-size: 28px;
  margin-top: 8px;
  color: #333;
}
</style>